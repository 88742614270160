<template>
  <b-card id="daily-cash-card">
    <!-- FILTERS BAR -->
    <b-row class="d-flex justify-content-between align-items-center">
      <b-col
        cols="auto"
        class="pt-25 d-flex justify-content-start align-items-center"
      >
        <b-form-input
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loadingPayments"
          debounce="400"
          autofocus
          class="mr-1"
        />
        <v-select
          v-model="otaFilter"
          :options="otaFilterOptions"
          :reduce="(option) => option.value"
          placeholder="Canal"
          :disabled="loadingPayments"
          class="w-100 ota-filter mr-1"
        />
        <b-form-checkbox-group
          v-model="selectedPaymentsTypes"
          :options="chargesRefundsOptions"
          value-field="value"
          text-field="name"
          class="d-flex align-items-center"
          :disabled="loadingPayments"
        />
        <b-form-checkbox
          v-model="showAccountedPayments"
          class="d-flex"
          :disabled="loadingPayments"
        >
          <span class="text-nowrap"> Comptabilitzats </span>
        </b-form-checkbox>
      </b-col>

      <b-col cols="auto">
        <b-button-group size="sm">
          <b-button
            v-b-tooltip.hover.bottom="'Setmana anterior'"
            variant="secondary"
            :disabled="loadingPayments"
            @click="previousWeek"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Avui'"
            variant="secondary"
            :disabled="loadingPayments"
            @click="currentWeek"
          >
            <feather-icon icon="CircleIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Setmana següent'"
            variant="secondary"
            :disabled="loadingPayments"
            @click="nextWeek"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- WEEKDAYS -->
    <b-row class="my-3">
      <b-col cols="12">
        <b-nav justified>
          <b-nav-item
            v-for="(day, index) in weekDays"
            :key="`planning-day-header-${index}`"
            :class="{
              'bg-primary shadow daily-cash-day-active': sameDates(
                day,
                selectedDay
              ),
            }"
            :active="sameDates(day, selectedDay)"
            class="py-25"
            @click="toggleSelectedDay(day)"
          >
            <div class="text-uppercase daily-cash-weekday">
              {{ getDayWeekday(day) }}
            </div>
            <div class="font-weight-bolder daily-cash-day-number">
              {{ getDayNumber(day) }}
            </div>
            <div class="daily-cash-month">
              {{ getDayMonth(day) }}
            </div>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <!-- PAYMENTS TABLE -->
    <b-row class="mb-1">
      <b-col cols="12">
        <daily-cash-table
          :payments="filteredGroupedPayments"
          :loading="loadingPayments"
          :filter="filter"
        />
      </b-col>
    </b-row>

    <!-- EXPORT BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col cols="12" sm="auto">
        <b-button
          block
          variant="primary"
          @click="previewAccountingCsv"
          :disabled="loadingPayments || !filteredGroupedPayments.length"
        >
          Exporta per comptabilitat
        </b-button>
        <b-modal
          id="preview-csv"
          title="Previsualització de l'exportació"
          size="xl"
          scrollable
          @ok="exportAccountingCsv"
          ok-title="Exporta"
          cancel-title="Cancel·la"
        >
          <div v-html="csvPreviewContent" />
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BNav,
  BNavItem,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BButtonGroup,
  BButton,
  VBTooltip,
  BModal,
} from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToDatabaseDateTime,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getOtaName,
  getServiceNameFromCode,
  notifyError,
  notifySuccess,
  sameDates,
  sameSign,
} from "@/utils/methods";
import DailyCashTable from "@/views/accounting/payments/daily-cash/components/DailyCashTable.vue";
import _debounce from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import vSelect from "vue-select";
import { otaOptions } from "@/utils/select-options";
import { saveAs } from "file-saver";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BNav,
    BNavItem,
    DailyCashTable,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BButtonGroup,
    BButton,
    vSelect,
    BModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedPaymentsTypes: ["CHARGES", "REFUNDS"],
      selectedDay: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedWeekEnd: new Date(new Date().setHours(0, 0, 0, 0)),
      selectedDaysNumber: 7,
      chargesRefundsOptions: [
        { value: "CHARGES", name: "Cobros" },
        { value: "REFUNDS", name: "Devolucions" },
      ],
      filter: null,
      otaFilter: null,
      weekDays: [],
      showAccountedPayments: true,
      sameDates,
      csvPreviewContent: null,
    };
  },
  created() {
    this.setWeekDays();
    this.fetchPayments(this);
    if (!this.loadingLedgerAccounts) {
      this.fetchLedgerAccounts();
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clientPayments/reset");
  },
  computed: {
    loadingLedgerAccounts() {
      return this.$store.getters["ledgerAccounts/loading"];
    },
    ledgerAccounts() {
      return this.$store.getters["ledgerAccounts/accounts"];
    },
    loadingPayments() {
      return this.$store.getters["clientPayments/loading"];
    },
    clientPayments() {
      return this.$store.getters["clientPayments/payments"];
    },
    groupedPayments() {
      return this.getGroupedPayments(this.clientPayments);
    },
    filteredGroupedPayments() {
      if (!this.groupedPayments?.length) {
        return [];
      }

      return this.groupedPayments
        .filter((groupedPayment) => {
          // Filter by status, payments are only VERIFIED or ACCOUNTED
          if (this.showAccountedPayments) {
            return true;
          }
          return groupedPayment.status !== "ACCOUNTED";
        })
        .filter((groupedPayment) => {
          // Filter by date
          return sameDates(
            this.selectedDay,
            new Date(groupedPayment.verifiedAt)
          );
        })
        .filter((groupedPayment) => {
          // Filter by type
          if (this.chargesSelected && this.refundsSelected) {
            return true;
          }
          if (this.chargesSelected) {
            return groupedPayment.totalSum > 0;
          }
          if (this.refundsSelected) {
            return groupedPayment.totalSum <= 0;
          }
          return false;
        })
        .filter((groupedPayment) => {
          // Filter by OTA
          if (!this.otaFilter) {
            return true;
          }

          if (this.otaFilter === "TPV") {
            return groupedPayment.method === "CREDIT_CARD";
          }

          if (this.otaFilter === "BANK_TRANSFERS") {
            return groupedPayment.method === "BANK_TRANSFER";
          }

          return (
            groupedPayment.booking?.source === this.otaFilter &&
            groupedPayment.method !== "BANK_TRANSFER" &&
            groupedPayment.method !== "CREDIT_CARD"
          );
        });
    },
    groupedPaymentsToExport() {
      const filteredClientPayments = this.clientPayments
        .filter((clientPayment) => {
          // Filter by status
          const statusToCheck = ["VERIFIED"];

          if (this.showAccountedPayments) {
            statusToCheck.push("ACCOUNTED");
          }

          return statusToCheck.includes(clientPayment.status);
        })
        .filter((clientPayment) => {
          // Filter by date
          return sameDates(
            this.selectedDay,
            new Date(clientPayment.verifiedAt)
          );
        })
        .filter((clientPayment) => {
          // Filter by type
          if (this.chargesSelected && this.refundsSelected) {
            return true;
          }
          if (this.chargesSelected) {
            return clientPayment.pvpAmount > 0;
          }
          if (this.refundsSelected) {
            return clientPayment.pvpAmount <= 0;
          }
          return false;
        })
        .filter((clientPayment) => {
          // Filter by OTA
          if (!this.otaFilter) {
            return true;
          }

          if (this.otaFilter === "TPV") {
            return clientPayment.method === "CREDIT_CARD";
          }

          if (this.otaFilter === "BANK_TRANSFERS") {
            return clientPayment.method === "BANK_TRANSFER";
          }

          return (
            clientPayment.booking?.source === this.otaFilter &&
            clientPayment.method !== "BANK_TRANSFER" &&
            clientPayment.method !== "CREDIT_CARD"
          );
        });

      return this.getGroupedPayments(filteredClientPayments);
    },
    chargesSelected() {
      const foundIndex = this.selectedPaymentsTypes.findIndex(
        (type) => type === "CHARGES"
      );
      return foundIndex !== -1;
    },
    refundsSelected() {
      const foundIndex = this.selectedPaymentsTypes.findIndex(
        (type) => type === "REFUNDS"
      );
      return foundIndex !== -1;
    },
    otasWithSelfManagedPayments() {
      return otaOptions.filter((ota) => ota.selfManagedPayments);
    },
    otaFilterOptions() {
      return [
        {
          label: "TPV's",
          value: "TPV",
        },
        {
          label: "Transferències",
          value: "BANK_TRANSFERS",
        },
        ...this.otasWithSelfManagedPayments,
      ];
    },
  },
  methods: {
    previewAccountingCsv() {
      const content = this.getAccountingCsvContent();
      this.csvPreviewContent = this.csvToHtmlTable(content);
      this.$bvModal.show("preview-csv");
    },
    csvToHtmlTable(csvText) {
      const lines = csvText.trim().split("\n");
      const table = document.createElement("table");
      table.style.borderCollapse = "collapse";
      table.style.width = "100%";

      lines.forEach((line, rowIndex) => {
        const row = document.createElement("tr");
        const cells = line.split(",");

        cells.forEach((cellValue, cellIndex) => {
          const cell =
            rowIndex === 0
              ? document.createElement("th")
              : document.createElement("td");
          cell.textContent = cellValue;
          cell.style.border = "1px solid lightgray";
          cell.style.padding = "5px";
          row.appendChild(cell);
        });

        table.appendChild(row);
      });

      return table.outerHTML;
    },
    exportAccountingCsv() {
      this.$store.dispatch("app/setLoading", true);

      const content = this.getAccountingCsvContent();
      const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      const todayDate = formatDateObjectToDatabaseDate(new Date());
      const otaName = this.otaFilter ? "-" + this.otaFilter.toLowerCase() : "";
      const filename = `${todayDate}-tesoreria-diaria${otaName}`;

      saveAs(blob, `${filename}.csv`);

      this.accountPayments()
        .then(() => {
          notifySuccess(
            "Pagaments comptabilitzat",
            "Els pagaments han estat comptabilitzats correctament."
          );
        })
        .catch(() => {
          notifyError(
            "Pagaments no comptabilitzat",
            "Hi ha hagut un error al comptabilitzat els pagaments."
          );
        })
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    getAccountingCsvContent() {
      const header = [
        "Data de verificació",
        "Compte",
        "Tipo D/H",
        "Contrapartida",
        "Concepte",
        "Import",
        "Nº Document",
        "Zona",
        "Allotjament",
        "Data de venciment",
        "Client",
        "Compte de ventes",
        "Compte d'IVA",
        "Marca",
      ];

      const text = [header.join(",")];

      text.push(
        this.getAccountingPaymentsHeaderTextLine(this.groupedPaymentsToExport)
      );

      this.groupedPaymentsToExport.forEach((groupedPayment) => {
        groupedPayment.clientPayments.forEach((clientPayment) => {
          text.push(this.getAccountingPaymentDetailTextLine(clientPayment));

          // ADD COMISSION LINE
          const { scope, booking } = clientPayment || {};
          const hasSelfManagedPayments = this.otasWithSelfManagedPayments.some(
            (ota) => ota.value === booking?.source
          );
          if (hasSelfManagedPayments && scope === "BOOKING") {
            text.push(
              this.getAccountingPaymentSourceComissionTextLine(clientPayment)
            );
          }
        });
      });

      return text.join("\n");
    },
    getAccountingPaymentsHeaderTextLine(groupedPayments) {
      const groupedPaymentsTotal = groupedPayments.reduce(
        (sum, groupedPayment) => {
          const { totalSum, clientPayments } = groupedPayment;
          const scopes = Array.from(groupedPayment.scopes);

          if (
            scopes.length === 1 &&
            scopes[0] === "SECURITY_DEPOSIT" &&
            totalSum <= 0
          ) {
            const securityDepositSum = clientPayments.reduce(
              (secDepSum, clientPayment) => {
                return (
                  secDepSum + (clientPayment.bookingService?.pvpPrice || 0)
                );
              },
              0
            );
            return sum - securityDepositSum;
          } else {
            return sum + groupedPayment.totalSum || 0;
          }
        },
        0
      );
      const groupedPaymentsSourceComissionTotal = groupedPayments.reduce(
        (sum, groupedPayment) => {
          return (sum += groupedPayment.sourceComissionSum || 0);
        },
        0
      );
      const textLineTotal =
        groupedPaymentsTotal - groupedPaymentsSourceComissionTotal;
      const isRefund = textLineTotal <= 0;
      const verifiedAt = groupedPayments[0]?.verifiedAt;
      const typeHD = this.getAccountingPaymentTextLineType(textLineTotal, true);
      const account = this.getAccountingPaymentsHeaderTextLineAccount(
        typeHD,
        false
      );
      const counterAccount = this.getAccountingPaymentsHeaderTextLineAccount(
        typeHD,
        true
      );
      const concept = this.getAccountingPaymentTextLineConcept();
      const amount = isRefund ? -(textLineTotal / 100) : textLineTotal / 100;

      return this.getAccountingCsvTextLine({
        verifiedAt,
        account,
        type: typeHD,
        counterAccount,
        concept,
        amount,
      });
    },
    getAccountingPaymentsHeaderTextLineAccount(type, isCounterAccount = false) {
      const shouldReturnAccount =
        (!isCounterAccount && type === "D") ||
        (isCounterAccount && type === "H");

      return shouldReturnAccount
        ? this.getGlobalLedgerAccountNumber("BANK")
        : "";
    },
    getAccountingPaymentDetailTextLine(clientPayment) {
      const {
        booking,
        pvpAmount,
        verifiedAt,
        scope,
        bookingService,
        localizator: clientPaymentLocalizator,
      } = clientPayment || {};
      const { accommodation, client, contract, checkin } = booking || {};

      const isSuburance = contract?.billingType === "SUBURANCE" || false;

      const bookingLocalizator = this.getBookingLocalizator(booking);

      const typeHD = this.getAccountingPaymentTextLineType(pvpAmount, false);
      const account = this.getAccountingPaymentDetailTextLineAccount(
        typeHD,
        booking,
        scope,
        false
      );
      const counterAccount = this.getAccountingPaymentDetailTextLineAccount(
        typeHD,
        booking,
        scope,
        true
      );
      const bookingServiceCode = bookingService?.service?.code || null;

      const isSecurityDepositRefund =
        bookingServiceCode === "SECURITY_DEPOSIT" && pvpAmount <= 0;

      // ! Only for the security deposit refund, the amount will be the
      // ! total price of the security deposit and not the real refunded
      // ! amount, for ledger accounts reasons.
      const amount = isSecurityDepositRefund
        ? bookingService?.pvpPrice / 100
        : pvpAmount / 100;

      const isRefund = pvpAmount <= 0;

      const concept = this.getAccountingPaymentTextLineConcept(
        scope,
        client?.fullName || bookingLocalizator,
        clientPaymentLocalizator,
        isRefund,
        bookingServiceCode
      );

      const expiryDate = this.getAccountingPaymentDetailTextLineExpiryDate(
        account || counterAccount,
        booking
      );
      const zone = accommodation?.details?.zoneLedgerAccount || "";
      const accommodationName = accommodation?.name || "";
      const clientName = client?.fullName || "";
      const salesAccount = this.getGlobalLedgerAccountNumber(
        isSuburance ? "SALES_SUBURANCE" : "SALES"
      );
      const vatAccount = this.getGlobalLedgerAccountNumber(
        isSuburance ? "VAT_SUBURANCE" : "VAT"
      );

      const nextYear = new Date().getFullYear() + 1;
      const mark = checkin > nextYear.toString() ? 1 : "";

      return this.getAccountingCsvTextLine({
        verifiedAt,
        account,
        type: typeHD,
        counterAccount,
        concept,
        amount: Math.abs(amount),
        document: bookingLocalizator,
        zone,
        accommodation: accommodationName,
        expiryDate,
        client: clientName,
        salesAccount,
        vatAccount,
        mark,
      });
    },
    getAccountingPaymentDetailTextLineAccount(
      type,
      booking,
      scope = null,
      isCounterAccount = false
    ) {
      const { accommodation, contract } = booking || {};

      const bookingLocalizator = this.getBookingLocalizator(booking);

      const shouldReturnAccount =
        (!isCounterAccount && type === "D") ||
        (isCounterAccount && type === "H");

      if (shouldReturnAccount) {
        if (scope === "BOOKING" && contract?.billingType === "SUBURANCE") {
          return this.getSuburanceBookingLedgerAccount(bookingLocalizator);
        } else {
          return this.getAccommodationLedgerAccountByScope(
            accommodation,
            scope
          );
        }
      } else {
        return "";
      }
    },
    getAccountingPaymentDetailTextLineExpiryDate(account, booking) {
      const accountStart = account.substring(0, 4);

      switch (accountStart) {
        case "4109":
        case "5610":
        case "5611":
        case "5612":
        case "4301":
          return booking.checkin;
        case "5600":
          return booking.checkout;
        default:
          return "";
      }
    },
    getAccountingPaymentSourceComissionTextLine(clientPayment) {
      const { booking, verifiedAt } = clientPayment || {};
      const { accommodation, sourceComissionPrice, source, checkin } =
        booking || {};

      const bookingLocalizator = this.getBookingLocalizator(booking);

      const typeHD = "D";
      const account = this.getGlobalLedgerAccountNumber(source);
      const counterAccount = "";
      const sourceName = getOtaName(source);
      const concept = `Comision ${sourceName}${
        bookingLocalizator ? " para reserva " + bookingLocalizator : ""
      }`;
      const amount = sourceComissionPrice / 100;
      const expiryDate = this.getAccountingPaymentDetailTextLineExpiryDate(
        account,
        booking
      );
      const zone = accommodation?.details?.zoneLedgerAccount || "";
      const accommodationName = accommodation?.name || "";

      const nextYear = new Date().getFullYear() + 1;
      const mark = checkin > nextYear.toString() ? 1 : 0;

      return this.getAccountingCsvTextLine({
        verifiedAt,
        account,
        type: typeHD,
        counterAccount,
        concept,
        amount,
        document: bookingLocalizator,
        zone,
        accommodation: accommodationName,
        expiryDate,
        mark,
      });
    },
    getAccommodationLedgerAccountByScope(accommodation, scope) {
      const defaultAccount = "";

      if (!accommodation || !scope) {
        return defaultAccount;
      }

      const ledgerAccounts = {
        BOOKING: accommodation?.details?.depositLedgerAccount || defaultAccount,
        SECURITY_DEPOSIT:
          accommodation?.details?.securityDepositLedgerAccount ||
          defaultAccount,
        TOURIST_TAX:
          accommodation?.details?.touristTaxLedgerAccount || defaultAccount,
        SERVICE:
          accommodation?.details?.servicesLedgerAccount || defaultAccount,
        OTHER: defaultAccount,
      };

      return ledgerAccounts[scope] || defaultAccount;
    },
    getSuburanceBookingLedgerAccount(localizator) {
      if (!localizator) {
        return null;
      }
      const initial = "4301";
      const initialLength = initial.length;
      const localizatorParts = localizator.split("-");
      const final =
        localizatorParts.length > 1 ? localizatorParts[1] : localizatorParts[0];
      const finalLength = final.length || 0;
      const ledgerAccountLength = 10;

      if (initialLength + finalLength >= ledgerAccountLength) {
        return (initial + final).substring(0, 10);
      }

      const paddingLength = ledgerAccountLength - (initialLength + finalLength);
      const padding = "0".repeat(paddingLength);

      return initial + padding + final;
    },
    getGlobalLedgerAccountNumber(code) {
      if (!this.ledgerAccounts?.length) {
        return "";
      }

      const foundLedgerAccount = this.ledgerAccounts.find(
        (ledgerAccount) => ledgerAccount.code === code
      );

      return foundLedgerAccount?.number || "";
    },
    getAccountingPaymentTextLineType(amount, isHeader) {
      return (isHeader && amount > 0) || (!isHeader && amount <= 0) ? "D" : "H";
    },
    getAccountingPaymentTextLineConcept(
      scope = null,
      clientOrLocalizator = null,
      paymentLocalizator = null,
      isRefund = false,
      bookingServiceCode = null
    ) {
      // Default concept
      const chargeOrRefund = isRefund ? "D" : "C";

      let concept = `${chargeOrRefund} por ${
        clientOrLocalizator ? "reserva" : "reservas"
      }`;

      // If the OTA filter is enabled, override the default concept
      if (this.otaFilter === "TPV") {
        concept = "TPVS";
      } else if (this.otaFilter === "BANK_TRANSFERS") {
        concept = "TRANSFERENCIAS";
      } else if (this.otaFilter) {
        concept = this.otaFilter;
      }

      // If the scope is defined, override the default concept
      if (scope) {
        const serviceName = getServiceNameFromCode(
          bookingServiceCode,
          this.$i18n.locale
        );

        switch (scope) {
          case "BOOKING":
            concept = `${chargeOrRefund} alojamiento`;
            break;
          case "SECURITY_DEPOSIT":
            concept = `${chargeOrRefund} fianza`;
            break;
          case "TOURIST_TAX":
            concept = `${chargeOrRefund} ecotasa`;
            break;
          case "SERVICE":
            concept = `${chargeOrRefund} ${
              serviceName ? serviceName.toLowerCase() : "servicio"
            }`;
            break;
          default:
            break;
        }
      }

      return `${concept}${
        paymentLocalizator ? " - " + paymentLocalizator : ""
      }${clientOrLocalizator ? " - " + clientOrLocalizator : ""}`;
    },
    getAccountingCsvTextLine(
      lineData = {
        verifiedAt: "",
        account: "",
        type: "",
        counterAccount: "",
        concept: "",
        amount: "",
        document: "",
        zone: "",
        accommodation: "",
        expiryDate: "",
        client: "",
        salesAccount: "",
        vatAccount: "",
        mark: "",
      }
    ) {
      return `${
        lineData.verifiedAt
          ? this.formatLedgerAccountDate(lineData.verifiedAt)
          : ""
      },${lineData.account || ""},${lineData.type || ""},${
        lineData.counterAccount || ""
      },${lineData.concept || ""},${lineData.amount || ""},${
        lineData.document || ""
      },${lineData.zone || ""},${lineData.accommodation || ""},${
        lineData.expiryDate
          ? this.formatLedgerAccountDate(lineData.expiryDate)
          : ""
      },${lineData.client || ""},${lineData.salesAccount || ""},${
        lineData.vatAccount || ""
      },${lineData.mark || ""}`;
    },
    getBookingLocalizator(booking) {
      const { source, sourceLocalizator, localizator } = booking || {};

      if (!localizator && !sourceLocalizator) {
        return null;
      }

      const bookingLocalizatorParts =
        source === "FORAVILA"
          ? sourceLocalizator
            ? [sourceLocalizator]
            : localizator.split("-")
          : localizator.split("-");

      const bookingLocalizator =
        bookingLocalizatorParts.length > 1
          ? bookingLocalizatorParts[1]
          : bookingLocalizatorParts[0];

      return bookingLocalizator;
    },
    getDayMonth(date) {
      const formatting = { month: "short" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    getDayWeekday(date) {
      const formatting = { weekday: "long" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    getDayNumber(date) {
      const formatting = { day: "numeric" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    toggleSelectedDay(day) {
      this.selectedDay = day;
    },
    fetchLedgerAccounts() {
      this.$store
        .dispatch("ledgerAccounts/fetchAccounts")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchLedgerAccounts.title"),
            this.$t("errors.fetchLedgerAccounts.description")
          )
        );
    },
    fetchPayments: _debounce((innerThis) => {
      // Calculate the week start first
      const weekStart = new Date(innerThis.selectedWeekEnd);
      weekStart.setDate(
        weekStart.getDate() - (innerThis.selectedDaysNumber - 1)
      );

      // Fetch the client payments in between week start and week end
      innerThis.$store
        .dispatch("clientPayments/fetchVerifiedPayments", {
          pagination: false,
          minDate: formatDateObjectToDatabaseDate(weekStart),
          maxDate: formatDateObjectToDatabaseDate(innerThis.selectedWeekEnd),
        })
        .catch(() => {
          notifyError(
            innerThis.$t("errors.fetchBooking.title"),
            innerThis.$t("errors.fetchBooking.description")
          );
        });
    }, 500),
    setWeekDays() {
      this.weekDays = [];

      for (let i = 0; i < this.selectedDaysNumber; i += 1) {
        const day = new Date(this.selectedWeekEnd);
        day.setDate(day.getDate() - i);
        this.weekDays.unshift(day);
      }
    },
    nextWeek() {
      this.selectedWeekEnd.setDate(this.selectedWeekEnd.getDate() + 7);
      this.selectedDay.setDate(this.selectedDay.getDate() + 7);
      this.setWeekDays();
      this.fetchPayments(this);
    },
    previousWeek() {
      this.selectedWeekEnd.setDate(this.selectedWeekEnd.getDate() - 7);
      this.selectedDay.setDate(this.selectedDay.getDate() - 7);
      this.setWeekDays();
      this.fetchPayments(this);
    },
    currentWeek() {
      this.selectedWeekEnd = new Date(new Date().setHours(0, 0, 0, 0));
      this.selectedDay = new Date(new Date().setHours(0, 0, 0, 0));
      this.setWeekDays();
      this.fetchPayments(this);
    },
    formatDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    formatLedgerAccountDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    groupedPaymentAlreadyExists(groupedPayment, clientPayment) {
      const bookingLocalizatorMatches =
        !!clientPayment.booking?.localizator &&
        clientPayment.booking.localizator ===
          groupedPayment.booking?.localizator;

      const paymentLocalizatorMatches =
        !!clientPayment.localizator &&
        clientPayment.localizator === groupedPayment.localizator;

      const dateMatches =
        clientPayment?.verifiedAt && groupedPayment?.verifiedAt
          ? sameDates(
              new Date(clientPayment.verifiedAt),
              new Date(groupedPayment.verifiedAt)
            )
          : false;

      const signMatches = sameSign(
        groupedPayment.total,
        clientPayment.pvpAmount
      );

      const methodMatches = clientPayment.method === groupedPayment.method;

      const baseCheck =
        bookingLocalizatorMatches &&
        dateMatches &&
        methodMatches &&
        signMatches;

      switch (clientPayment.method) {
        case "SOURCE_PAYMENT":
          return baseCheck;
        default:
          return baseCheck && paymentLocalizatorMatches;
      }
    },
    createGroupedPayment(clientPayment) {
      const { localizator, booking, method, status, verifiedAt, scope } =
        clientPayment || {};

      const groupedPayment = {
        customId: uuidv4(),
        localizator,
        method,
        status,
        verifiedAt,
        sortVerifiedAt: formatDateStringToDatabaseDate(verifiedAt) || null,
        displayVerifiedAt: this.formatDate(verifiedAt) || null,
        booking,
        totalSum: 0,
        bookingSum: 0,
        securityDepositSum: 0,
        touristTaxSum: 0,
        touristTaxDescriptions: [],
        servicesSum: 0,
        servicesDescriptions: [],
        sourceComissionSum: 0,
        clientPayments: [clientPayment],
        scopes: new Set([scope]),
      };

      this.updateGroupedPaymentAmounts(groupedPayment, clientPayment);

      return groupedPayment;
    },
    updateGroupedPayment(groupedPayment, clientPayment) {
      groupedPayment.clientPayments.push(clientPayment);
      groupedPayment.scopes.add(clientPayment.scope);
      this.updateGroupedPaymentAmounts(groupedPayment, clientPayment);
      this.updateGroupedPaymentStatus(groupedPayment);
    },
    updateGroupedPaymentAmounts(groupedPayment, clientPayment) {
      const { scope, pvpAmount, bookingService, booking } = clientPayment || {};
      const { source, sourceComissionPrice } = booking || {};
      const hasSelfManagedPayments = this.otasWithSelfManagedPayments.some(
        (ota) => ota.value === source
      );

      switch (scope) {
        case "BOOKING":
          groupedPayment.bookingSum += pvpAmount || 0;
          if (hasSelfManagedPayments) {
            groupedPayment.sourceComissionSum += sourceComissionPrice || 0;
          }
          break;
        case "SECURITY_DEPOSIT":
          groupedPayment.securityDepositSum += pvpAmount || 0;
          break;
        case "TOURIST_TAX":
          groupedPayment.touristTaxSum += pvpAmount || 0;
          groupedPayment.touristTaxDescriptions.push(bookingService);
          break;
        case "SERVICE":
        default:
          groupedPayment.servicesSum += pvpAmount || 0;
          groupedPayment.servicesDescriptions.push(bookingService);
          break;
      }

      groupedPayment.totalSum += pvpAmount || 0;
    },
    updateGroupedPaymentStatus(groupedPayment) {
      const clientPaymentsStatus = new Set(
        groupedPayment.clientPayments.map(
          (clientPayment) => clientPayment.status
        )
      );
      if (clientPaymentsStatus.size > 1) {
        groupedPayment.status = "PARTIALLY_ACCOUNTED";
      }
    },
    getGroupedPayments(clientPayments) {
      const groupedPayments = [];

      clientPayments.forEach((clientPayment) => {
        const foundGroupedPaymentIndex = groupedPayments.findIndex(
          (groupedPayment) =>
            this.groupedPaymentAlreadyExists(groupedPayment, clientPayment)
        );

        if (foundGroupedPaymentIndex === -1) {
          groupedPayments.push(this.createGroupedPayment(clientPayment));
        } else {
          this.updateGroupedPayment(
            groupedPayments[foundGroupedPaymentIndex],
            clientPayment
          );
        }
      });

      return groupedPayments;
    },
    accountPayments() {
      const uuids = [];

      this.groupedPaymentsToExport.forEach((groupedPayment) => {
        groupedPayment.clientPayments.forEach((clientPayment) => {
          if (clientPayment.status !== "ACCOUNTED") {
            uuids.push(clientPayment.uuid);
          }
        });
      });

      const promises = [];

      uuids.forEach((uuid) => {
        promises.push(this.accountPayment(uuid));
      });

      return Promise.all(promises);
    },
    accountPayment(uuid) {
      return this.$store.dispatch("clientPayments/updatePayment", {
        uuid,
        status: "ACCOUNTED",
        accountedAt: formatDateObjectToDatabaseDateTime(new Date()),
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#daily-cash-card {
  .daily-cash-day-active a {
    color: white;
  }
  .daily-cash-weekday {
    font-size: 10px;
  }
  .daily-cash-month {
    font-size: 12px;
  }
  .daily-cash-day-number {
    font-size: 20px;
    color: #666;
  }
  .ota-filter {
    min-width: 200px;
  }
}
</style>
